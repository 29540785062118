<template>
  <div>
    <el-tabs v-model="tab.active">
      <el-tab-pane
        v-if="form.model.company.planMode === 'Paquete'"
        :label="$tc('package', 4)"
        name="package"
      >
        <company-service-feature :company="form.model.company"> </company-service-feature>
        <br />
        <div v-if="form.model.company.companyServiceIsSubscribedCollect" class="container">
          <div class="current current-plan">
            <div class="col col-select">
              <div class="card">
                <div class="card-header">
                  <h2 class="title">
                    {{ $tc('cardSubscribed') }}
                  </h2>
                </div>
                <div class="card-body" style="margin-top: 3em">
                  <div class="card-body" style="margin-top: 1em">
                    <img
                      v-if="
                        form.model.placeToPayRecurrent.placeToPayRecurrentFranchiseName ===
                        'Mastercard'
                      "
                      src="/img/place_to_pay/MASTERCARD.png"
                      alt="Mastercard"
                      height="20"
                    />
                    <div class="card-body" style="margin-top: 1em">
                      <img
                        v-if="
                          form.model.placeToPayRecurrent.placeToPayRecurrentFranchiseName === 'Visa'
                        "
                        src="/img/place_to_pay/VISA.png"
                        alt="Visa"
                        height="20"
                      />
                    </div>
                    <div class="card-body" style="margin-top: 1em">
                      <img
                        v-if="
                          form.model.placeToPayRecurrent.placeToPayRecurrentFranchiseName ===
                          'American Express'
                        "
                        src="/img/place_to_pay/AMEX.png"
                        alt="American Express"
                        height="20"
                      />
                    </div>
                    <p>
                      {{ $tc('isSuscribed') }}
                    </p>
                    <div class="card-body" style="margin-top: 1.5em">
                      <div class="line"></div>
                      <el-button type="primary" @click="onDeleteSubscription">
                        {{ $tc('deleteSubscription') }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="form.model.company.planMode !== 'Paquete'"
        :label="$tc('current')"
        name="current"
      >
        <div v-if="form.model.company.companyServiceIsSubscribedCollect" class="container">
          <div class="current current-plan">
            <div class="col col-select">
              <div class="card">
                <div class="card-header">
                  <h2 class="title">
                    {{ $tc('cardSubscribed') }}
                  </h2>
                </div>
                <div class="card-body" style="margin-top: 3em">
                  <div class="card-body" style="margin-top: 1em">
                    <img
                      v-if="
                        form.model.placeToPayRecurrent.placeToPayRecurrentFranchiseName ===
                        'Mastercard'
                      "
                      src="/img/place_to_pay/MASTERCARD.png"
                      alt="Mastercard"
                      height="20"
                    />
                    <div class="card-body" style="margin-top: 1em">
                      <img
                        v-if="
                          form.model.placeToPayRecurrent.placeToPayRecurrentFranchiseName === 'Visa'
                        "
                        src="/img/place_to_pay/VISA.png"
                        alt="Visa"
                        height="20"
                      />
                    </div>
                    <div class="card-body" style="margin-top: 1em">
                      <img
                        v-if="
                          form.model.placeToPayRecurrent.placeToPayRecurrentFranchiseName ===
                          'American Express'
                        "
                        src="/img/place_to_pay/AMEX.png"
                        alt="American Express"
                        height="20"
                      />
                    </div>
                    <p>
                      {{ $tc('isSuscribed') }}
                    </p>
                    <div class="card-body" style="margin-top: 1.5em">
                      <div class="line"></div>
                      <el-button type="primary" @click="onDeleteSubscription">
                        {{ $tc('deleteSubscription') }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <current-plan :company="form.model.company"></current-plan>
      </el-tab-pane>
      <el-tab-pane
        v-if="
          form.model.company.planMode === 'Periodicidad' &&
          form.model.planPackagePeriodicity.length > 0
        "
        label="Comprar paquete"
        name="packagePeriodicity"
      >
        <el-alert type="warning" :closable="false">
          <p>
            ¡RECUERDE! El paquete a comprar tendrán una fecha de vencimiento
            {{ form.model.company.companyServiceDateNextInvoice | formatToDate }}
          </p>
        </el-alert>
        <el-row :gutter="20">
          <el-col
            v-for="(itemPlanPackagePeriodicity, indexPlanPackagePeriodicity) in form.model
              .planPackagePeriodicity"
            :key="indexPlanPackagePeriodicity"
            :sm="6"
          >
            <div class="container" style="height: 380px; overflow: auto; padding-top: 10px">
              <div class="current current-plan">
                <div class="col col-select">
                  <div class="card">
                    <div class="card-header">
                      <h2 class="title">
                        {{ itemPlanPackagePeriodicity.planName }}
                        ({{ $tc('active') }})
                      </h2>
                      <p class="price">
                        {{
                          itemPlanPackagePeriodicity.planPriceMonthly | formatToCurrencyWithIsoCode
                        }}
                      </p>
                      <span class="description"></span>
                      <div class="line"></div>
                    </div>
                    <table class="features" width="100%">
                      <tbody>
                        <tr
                          v-for="(
                            itemFeature, indexFeature
                          ) in itemPlanPackagePeriodicity.features.filter(function (f) {
                            return +f.featureValue !== 0
                          })"
                          :key="indexFeature"
                        >
                          <td align="center">
                            <span v-if="+itemFeature.featureValue > 0" class="tag success">
                              <small>{{ itemFeature.featureValue }}</small>
                            </span>
                            <span v-if="+itemFeature.featureValue < 0" class="tag primary">
                              {{ $tc('unlimited') }}
                            </span>
                          </td>
                          <td>{{ itemFeature.featureName }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <el-button
                      size="mini"
                      class="btn btn-select"
                      @click="onGoToPlanPackagePeriodicitySelect(itemPlanPackagePeriodicity)"
                    >
                      <i class="fad fa-shopping-cart"></i>
                      {{ $tc('purchasePackage', 1) }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$tc('voucher')" name="voucher">
        <el-table :data="tables.voucher.data" :loading="form.loading" style="width: 100%" stripe>
          <el-table-column :label="$tc('status')" :width="100" align="center">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.voucherStatus !== 'Paid' && scope.row.voucherStatus !== 'Open'"
                :content="$options.filters.formatToDate(scope.row.voucherStatusDate)"
                placement="right"
              >
                <span>
                  <i class="fad fa-file-alt text-warning"></i>
                  {{ $tc($options.filters.toCamelCase(scope.row.voucherStatus)) }}
                </span>
              </el-tooltip>
              <el-tooltip
                v-else
                :content="$options.filters.formatToDate(scope.row.voucherStatusDate)"
                effect="dark"
                placement="right"
              >
                <span>
                  <i class="fad fa-file-alt text-success"></i>
                  {{ $tc($options.filters.toCamelCase(scope.row.voucherStatus)) }}
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$tc('pay')" :width="100" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="
                  scope.row.voucherStatus === 'Open' || scope.row.voucherStatus === 'PartialPayment'
                "
                size="mini"
                type="success"
                @click="onPayment(scope.row)"
              >
                {{ $tc('pay') }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            :label="$tc('no')"
            :width="50"
            align="center"
            prop="voucherConsecutive"
          ></el-table-column>
          <el-table-column type="expand" width="30">
            <template slot-scope="scope">
              <p>
                <strong>{{ $tc('year') }}: </strong>
                {{ scope.row.voucherYear }}
                <strong>{{ $tc('month') }}: </strong>
                {{ scope.row.voucherMonth }}
              </p>
              <p>
                <strong>{{ $tc('period') }}: </strong>
                {{ scope.row.voucherDateStart | formatToDate }} -
                {{ scope.row.voucherDateEnd | formatToDate }}
              </p>
              <p>
                <strong>{{ $tc('recurrence') }}: </strong>
                {{ $tc($options.filters.toCamelCase(scope.row.voucherRecurrence)) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column :label="$tc('date')" :width="85">
            <template slot-scope="scope">
              {{ scope.row.voucherDate | formatToDate }}
            </template>
          </el-table-column>
          <el-table-column :label="$tc('due')" :width="85">
            <template slot-scope="scope">
              {{ scope.row.voucherDateDue | formatToDate }}
            </template>
          </el-table-column>
          <el-table-column :label="$tc('amount')" :width="120" align="center">
            <template slot-scope="scope">
              {{ $options.filters.formatToCurrencyWithIsoCode(scope.row.voucherAmountTotal) }}
            </template>
          </el-table-column>
          <el-table-column :label="$tc('paymentByCollect')" :width="220" align="center ">
            <template slot-scope="scope">
              <el-button
                v-if="
                  (scope.row.voucherStatus === 'Open' ||
                    scope.row.voucherStatus === 'PartialPayment') &&
                  scope.row.companyServiceIsSubscribedCollect
                "
                size="mini"
                type="success "
                @click="onCollect(scope.row)"
              >
                {{ $tc('paymentByCollect') }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :label="$tc('payment', 2)" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="onViewPlaceToPayPayments(scope.row)">
                {{ $tc('viewPayments') }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :label="$tc('invoice')">
            <template slot-scope="scope">
              <span v-if="scope.row.invoiceName" class="tag info">
                <a
                  :href="
                    'https://app.aliaddo.com/invoices/Tracking?apiKey=26fc9f6572f34d7ba6b0519f12f4232a&email=&id=' +
                    scope.row.invoiceId
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ scope.row.invoiceName }}
                </a>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-alert
          v-if="form.model.company.companyServiceIsSubscribedCollect"
          type="dark"
          :closable="false"
        >
          <p>
            {{ $tc('isSuscribed') }}
            <el-button size="mini" type="text" @click="onDeleteSubscription">
              {{ $tc('deleteSubscription') }}
            </el-button>
          </p>
        </el-alert>
      </el-tab-pane>
    </el-tabs>
    <!-- #endregion  -->
    <!-- #region  payments  -->
    <el-dialog
      :loading="dialog.payments.loading"
      :visible.sync="dialog.payments.visible"
      width="90%"
      append-to-body
    >
      <payments v-if="dialog.payments.visible" :voucher-id="form.model.voucher.id"></payments>
    </el-dialog>
    <!-- #endregion  -->
  </div>
</template>
<script>
import { formatToDate } from '@/filters'
import { sendMessage } from '@/helpers'
import dialog, { showDialog } from '@/views/common/dialog'
import form from '@/views/common/form'
import model from '@/views/placeToPay/model'

export default {
  components: {
    payments: _ => import('@/views/placeToPay/payments'),
    currentPlan: _ => import('@/views/plan/current'),
    companyServiceFeature: _ => import('@/views/company-service-feature/public')
  },
  data() {
    return {
      dialog: {
        askedQuestionsElectronicPayments: {
          ...dialog()
        },
        payment: {
          ...dialog()
        },
        payments: {
          ...dialog()
        }
      },
      form: form({ model }),
      tab: {
        active: 'voucher'
      },
      model: {
        selected: {
          voucherId: ''
        }
      },
      tables: {
        voucher: {
          data: []
        }
      }
    }
  },
  mounted() {
    this.getData()
    this.getCompany()
    this.getPlansPackagePeriodicities()
    this.setCompany()
  },
  methods: {
    showDialog,
    onPayment(voucher) {
      const _this = this

      // path: 'placeToPay/:placeToPayReferenceId/:companyId/:serviceId/payment/voucher',
      sendMessage({
        type: 'openUrl',
        url: `${process.env.VUE_APP_URI_HUB}payments/placeToPay/${voucher.id}/${_this.$route.params.companyId}/${_this.$route.params.serviceId}/payment/voucher`
      })
      window.parent.location.href = `${process.env.VUE_APP_URI_HUB}payments/placeToPay/${voucher.id}/${_this.$route.params.companyId}/${_this.$route.params.serviceId}/payment/voucher`
    },
    onResultPayment(result) {
      const _this = this
      if (result.payment && result.payment.status && result.payment.status.status === 'REJECTED') {
        _this.$message({
          message: _this.$tc('paymentRejected'),
          type: 'error'
        })
      } else if (
        result.payment &&
        result.payment.status &&
        result.payment.status.status === 'APPROVED'
      ) {
        _this.$message({
          message: _this.$tc('paymentSuccess'),
          type: 'success'
        })
      } else if (
        result.payment &&
        result.payment.status &&
        result.payment.status.status === 'PENDING'
      ) {
        _this.$message({
          message: _this.$tc('paymentPending'),
          type: 'warning'
        })
      } else if (result.payment && result.payment.status && result.payment.status) {
        _this.$message({
          message: _this.$tc('paymentWithStatus', {
            status: result.payment.status.status
          }),
          type: 'info'
        })
      } else if (result.placeToPay && result.placeToPay.placeToPayStatusMessage) {
        _this.$message({
          message: result.placeToPay.placeToPayStatusMessage,
          type: 'info'
        })
      } else {
        _this.$message({
          message: _this.$tc('noHasPayment'),
          type: 'info'
        })
      }
    },
    onCollect(voucher) {
      const _this = this
      _this
        .$msgbox({
          title: _this.$tc('paymentByCollect'),
          message: _this.$tc('paymentByCollectMessage'),
          showCancelButton: true,
          confirmButtonText: _this.$tc('pay'),
          cancelButtonText: _this.$tc('cancel'),
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              _this.$http
                .post(`payments/placeToPay/${voucher.id}/collect`)
                .then(response => {
                  _this.onResultPayment(response.body)
                  done()
                })
                .finally(_ => {
                  _this.form.loading = false
                  instance.confirmButtonLoading = false
                })
            } else {
              done()
            }
          }
        })
        .then(_ => _this.getData())
        .finally(_ => (_this.loading.collect = false))
    },
    onDeleteSubscription() {
      const _this = this

      return _this
        .$confirm(_this.$tc('deleteSubscriptionConfirmMessage'), _this.$tc('confirm'), {
          confirmButtonText: _this.$tc('delete'),
          cancelButtonText: _this.$tc('cancel'),
          type: 'warning'
        })
        .then(() => {
          _this.form.loading = true
          _this.$http
            .delete(`placeToPayRecurrents/${_this.form.model.company.id}`)
            .then(_ => _this.getCompany())
            .finally(() => {
              _this.form.loading = false
            })
        })
        .catch(() => false)
    },
    onViewPlaceToPayPayments(voucher) {
      const _this = this
      _this.form.model.voucher = voucher
      _this.showDialog('payments')
    },
    getCompany() {
      const _this = this
      _this.form.loading = true
      _this.$http
        .get(
          `companies/${_this.$route.params.companyId}/${_this.$route.params.serviceId}/findByService`
        )
        .then(response => {
          _this.form.model.company = response.body
          _this.tab.active = _this.form.model.company.planMode === 'Paquete' ? 'package' : 'current'
          _this.getPlaceToPayRecurrent()
        })
        .finally(() => {
          _this.form.loading = false
        })
    },
    getPlaceToPayRecurrent() {
      const _this = this

      _this.form.loading = true
      _this.$http
        .get(`placeToPayRecurrents/${_this.form.model.company.id}`)
        .then(result => {
          if (result.body) {
            _this.form.model.placeToPayRecurrent = result.body
            _this.form.model.company = {
              ..._this.form.model.company,
              companyServiceIsSubscribedCollect: true
            }

            return
          }
          _this.form.model.placeToPayRecurrent = {}
          _this.form.model.company = {
            ...form.model.company,
            companyServiceIsSubscribedCollect: false
          }
        })
        .finally(() => {
          _this.form.loading = false
        })
    },
    getPlansPackagePeriodicities() {
      const _this = this
      _this.form.loading = true
      _this.$http
        .get(`plans/findByService/${_this.$route.params.serviceId}`)
        .then(response => {
          _this.form.model.planPackagePeriodicity = response.body.filter(
            p => p.planMode === 'paquetePeriodicidad'
          )
        })
        .finally(() => {
          _this.form.loading = false
        })
    },
    getData() {
      const _this = this
      _this.form.loading = true
      _this.$http
        .get(`vouchers/${_this.$route.params.companyId}/findByCompany`)
        .then(response => {
          _this.tables.voucher.data = response.body
        })
        .finally(() => {
          _this.form.loading = false
        })
    },
    setCompany() {
      const _this = this
      _this.form.model.companyId = _this.$route.params.companyId
    },
    onGoToPlanPackagePeriodicitySelect(plan) {
      const _this = this

      _this
        .$confirm(
          `¡RECUERDE! El paquete a comprar tendran una fecha de vencimiento ${formatToDate(
            _this.form.model.company.companyServiceDateNextInvoice
          )}`,
          'Warning',
          {
            confirmButtonText: 'SI',
            cancelButtonText: 'NO',
            type: 'warning'
          }
        )
        .then(() => {
          let serviceCode = ''
          if (_this.$route.params.serviceId === '1') {
            serviceCode = 'fe'
          } else if (_this.$route.params.serviceId === '2') {
            serviceCode = 'aliaddo'
          } else if (_this.$route.params.serviceId === '7') {
            serviceCode = 'isv'
          } else if (_this.$route.params.serviceId === '8') {
            serviceCode = 'aliaddopos'
          } else if (_this.$route.params.serviceId === '11') {
            serviceCode = 'aliaddonominalite'
          } else if (_this.$route.params.serviceId === '10') {
            serviceCode = 'aliaddonominafull'
          } else if (_this.$route.params.serviceId === '12') {
            serviceCode = 'aliaddonominaisv'
          }
          const urlPlanSelect = `${process.env.VUE_APP_URI_HUB}signup/select/${_this.form.model.company.id}/${plan.planName}/PaquetePeriodicidad/?source=package&product=${serviceCode} - PaquetePeriodicidad - ${_this.form.model.company.companyServiceFrequencyBilling} / PaquetePeriodicidad`
          sendMessage({ type: 'openUrl', url: urlPlanSelect })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/select-plan.scss';
.col {
  width: auto;
  .features {
    margin: 2px auto;
  }
}
div ::v-deep .col.col-select {
  margin: 0px;
}
</style>
