import { personAddress } from '@/views/signup/model-person'

export default () => ({
  serviceId: 0,
  paymentRecurringCollect: false,
  voucher: {
    voucherDetails: [],
  },
  plan: {},
  isFirstVoucher: false,
  address: personAddress(),
  company: {
    companyServiceFrequencyBilling: 'Yearly',
  },
  planPackagePeriodicity: [],
  placeToPay: {
    placeToPayAmount: '',
    placeToPayAmountPaid: '',
    placeToPayCus: '',
    placeToPayFranchise: '',
    placeToPayFranchiseName: '',
    placeToPayIsCollectionRecurrent: '',
    placeToPayIvaPercent: '',
    placeToPayIvaValue: '',
    placeToPayMethod: '',
    placeToPayProcessUrl: '',
    placeToPayReteFuentePercent: '',
    placeToPayReteFuenteValue: '',
    placeToPayReteIcaPercent: '',
    placeToPayReteIcaValue: '',
    placeToPayReteIvaPercent: '',
    placeToPayReteIvaValue: '',
    placeToPayStatus: '',
    placeToPayStatusDate: '',
    placeToPayStatusMessage: '',
    placeToPayStatusReason: '',
    referenceId: '',
    requestId: '',
  },
  redirectRequest: {
    buyer: {
      address: '',
      company: '',
      document: '',
      documentType: '',
      email: '',
      mobile: '',
      name: '',
      surname: '',
      creditCard: '',
    },
    expiration: '',
    ipAddress: '',
    paymentType: '_PSE_',
    paymentMethod: '_PSE_',
    returnUrl: '',
    userAgent: '',
  },
})
